import SimplexNoise from "simplex-noise";

export class NoiseMover {
  constructor(configObj) {
    this.simplex = new SimplexNoise();
    this.incrX = configObj.incrX;
    this.incrY = configObj.incrY;
    this.posX = 0;
    this.posY = 0;
    this.noiseScale = configObj.scale;
  }

  move() {
    this.posX += this.incrX;
    this.posY += this.incrY;
  }

  getValue(coords) {
    return this.simplex.noise2D(
      (coords.x + this.posX) / this.noiseScale,
      (coords.y + this.posY) / this.noiseScale
    );
  }
}
