import { initPixi } from "./pixi_init";
import { NoiseMover } from "./NoiseMover";
import { get2DCoordinatesOfIndex } from "./tools.js";
import { getIndexOf2DCoordinates } from "./tools.js";
import { animate as animation } from "./tools";
import { links, phrases } from "./textObj";
import { Sign } from "./Sign";
import { Menubar } from "./Menubar";
import { LiveData } from "./liveData";
// import anime from "animejs";
// import { mapRange } from "./tools";
// import chroma from "chroma-js";

const resolution = 20;
const colorArray = ["#5A7FE0", "#F85646", "#8BD375"];
const shadeNoise = new NoiseMover({
  incrX: 0.2,
  incrY: 0.2,
  scale: 50,
}),
  fontSize = 12;
let { PIXI, app, signMap, cols, rows } = initPixi(resolution, fontSize);
let signArray = Array.from(signMap.values());
let mode = "auto";
let windowResizeTimeOutId;
let menubar = new Menubar();
let liveData = new LiveData();

init();

function reset() {
  const aniFrames = window.requestAnimatioFrame(() => { });
  debugger;
  //   cancelAllAnimationFrames();
  //   app.stage.destroy(true);
  //   app.stage = null;

  cols = Math.floor(app.screen.width / resolution);
  rows = Math.floor(app.screen.height / resolution);
  const colWidth = app.screen.width / cols;
  const rowHeight = app.screen.height / rows;

  const fontName = "cassius mod";
  PIXI.BitmapFont.from(
    fontName,
    {
      fontFamily: "Cassius-Regular",
      fill: "#FFFFFF",
      fontSize: fontSize,
      align: "center",
      // // anchor: (0.5, 0.5),
      // anchor: new PIXI.Point(1),
    },
    {
      chars: [["a", "z"], ["0", "9"], ["A", "Z"], "§?!"],
    },
    { resolution: 2 }
    // { resolution: devicePixelRatio }
  );
  signMap.clear();

  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      const initSign = "§";
      // const bitmapTexty = new PIXI.BitmapText(initSign, { fontName });
      const bitmapTexty = new PIXI.Text(initSign);
      bitmapTexty.style = new PIXI.TextStyle({
        fontFamily: "Cassius-Regular",
        fill: "0xFFFFFF",
        fontSize: fontSize,
        align: "center",
      });
      //   signArray.push(bitmapTexty);
      bitmapTexty.position.set(
        x * colWidth + colWidth / 2 - bitmapTexty.width / 2,
        y * rowHeight + rowHeight / 2 - bitmapTexty.height / 2
      );
      //   bitmapTexty.alpha = 0;
      bitmapTexty.updateText();
      bitmapTexty.interactive = true;
      bitmapTexty.hitArea = new PIXI.Rectangle(0, 0, colWidth, rowHeight);

      app.stage.addChild(bitmapTexty);
      // app.stage.addChild(target);
      signMap.set(bitmapTexty, new Sign(bitmapTexty));
    }
  }

  signArray = [];
  signArray = Array.from(signMap.values());
  fillGridWithText("DATA", signArray);
  setupMenu();

  //   fadeInGrid();

  //   debugger;
  //   startAnimations();
  // console.log("after fadein");
}

async function init() {
  fillGridWithText("DATA", signArray);
  setupMenu();
  //   debugger;
  // console.log("init");
  await initIntro();
  // console.log("after intro");
  startAnimations();
  menubar.show();
  await fadeInGrid();

  // console.log("after fadein");
}

async function initIntro() {
  return new Promise((resolve) => {
    const md = "MORE D§TA";
    // const signArray = Array.from(signMap.values());
    // debugger;
    const startPos = {
      x: Math.floor(cols / 2 - md.length / 2),
      y: Math.floor(rows / 2),
    };
    // let introItems = [];

    for (let i = 0; i < md.length; i++) {
      const index = getIndexOf2DCoordinates(
        { x: startPos.x + i, y: startPos.y },
        cols
      );
      const item = signArray[index];
      // item.isAuto = true;
      // item.hide = false;
      // item.color = "white";
      item.bitmapText.text = md[i];
      item.bitmapText.alpha = 1;
      item.isLockedColor = true;

      //   introItems.push(item);

      animation({
        duration: 6000,
        timing(timeFraction) {
          return timeFraction;
        },
        draw(progress, elapsedSinceLastExecute, elapsed) {
          let start = Math.floor(progress * md.length);
          item.setSign(md[i]);
          // console.log(start);
          if (i > start) {
            item.setRndSignFromCharset();
          }
        },
        end() {
          return resolve();
        },

        name: "Intro",
        fps: 20,
      });
    }
  });
}

async function fadeInGrid() {
  // console.log("fading in grid");

  return animation({
    duration: 3000,
    timing(timeFraction) {
      // return Math.pow(timeFraction, 2);
      //   Math.log(a) / Math.log(b);
      //   return Math.log(timeFraction) / Math.log(2);
      //   return 1 - Math.sin(Math.acos(timeFraction));
      return timeFraction;
    },
    draw(progress) {
      for (let i = 0; i < signArray.length; ++i) {
        if (signArray[i].bitmapText.alpha < 1) {
          signArray[i].bitmapText.alpha = progress;
          //   console.log(progress);
        }
      }
    },
    name: "fade in",
    fps: 40,
  });

  //   signArray.map((el) => {
  //     el.hide = false;
  //   });
}

function setupMenu() {
  const menuItems = [];
  for (let i = 1; i > 0; i--) {
    // debugger;
    let index = getIndexOf2DCoordinates({ x: cols - 1 - i, y: 1 }, cols);
    menuItems.push(signArray[index]);
    // index = getIndexOf2DCoordinates({ x: cols - 1 - i, y: 2 }, cols);
    // menuItems.push(signArray[index]);
  }
  for (const item of menuItems) {
    item.charset = "?!";
    // item.isRndChar = true;
    // debugger;
    item.setSign("?");
    item.setColor("#ffffff");
    item.isMenu = true;
    item.isLockedColor = true;
    item.isLockedSign = true;

    item.bitmapText.interactive = true;
    item.bitmapText.on("mousedown", toggleMenu);
    item.bitmapText.on("touchstart", toggleMenu);
    item.bitmapText.cursor = "pointer";

    animation({
      duration: 0,
      timing(timeFraction) {
        return timeFraction;
      },
      draw(progress, elapsedSinceLastExecute, elapsed) {
        item.iterCharSet();
      },
      name: "Menu Inter Loop",
      fps: 1,
    });
  }
}

async function toggleMenu(eventData) {
  // console.log(eventData.currentTarget);
  // console.log(eventData.target.text);
  mode = "clean";
  // debugger;
  // console.log("before reset");
  document.querySelector('.menubar').classList.remove('menubar--visible');
  await resetGrid();
  // console.log("after reset");
  showMenu();
  document.querySelector('.menubar').classList.add('menubar--visible');
  await setDelay(1000);
  mode = "auto";
  // console.log("mode: " + mode);
  // const item = signMap.get(eventData.currentTarget);
  // item.isRndChar = true;
}

function showMenu() {
  for (const link of links) {
    const textObj = link;
    const color = colorArray[Math.floor(Math.random() * colorArray.length)];
    showText(textObj, true, color);
  }
}

async function resetGrid() {
  mode = "clean";

  for (let row = 0; row < rows; row++) {
    // const item = signArray[i];

    animation({
      duration: 80,
      timing(timeFraction) {
        return timeFraction;
      },
      draw(progress, elapsedSinceLastExecute, elapsed) {
        // console.log("start");
        for (let col = 0; col < cols; col++) {
          const index = getIndexOf2DCoordinates({ x: col, y: row }, cols);
          const item = signArray[index];
          item.isLockedSign = false;
          item.setRndSignFromCharset();
        }
        // let i = Math.floor(progress * text.length);
        // signArray[index].displayInfo(text[i], color);
      },
      end() {
        for (let col = 0; col < cols; col++) {
          const index = getIndexOf2DCoordinates({ x: col, y: row }, cols);
          const item = signArray[index];
          item.setSign(item.initialSign);
        }
      },
      name: "Font Fade",
      fps: 18,
    });

    await setDelay(10);
  }

  //   console.log("after gridreset");
  //   await setDelay(6000);
  //   console.log("after gridreset + 6000");
  //   mode = "auto";
}

function fillGridWithText(text, grid) {
  // const wallWord = 'data';

  //   const grid = Array.from(signMap.keys());
  const wallWord = text;
  const updatedArr = grid.map((el, i) => {
    el.bitmapText.text = wallWord[i % wallWord.length];
    el.initialSign = wallWord[i % wallWord.length];
  });
  return updatedArr;
}

function startAnimations() {
  const theLoop = animation({
    duration: 0,
    timing(timeFraction) {
      return timeFraction;
    },
    draw(progress, elapsedSinceLastExecute, elapsed) {
      mainLoop();
    },
    name: "Main Loop",
    fps: 20,
  });
  // console.log(theLoop);
}

function mainLoop() {
  for (let i = 0; i < signArray.length; ++i) {
    const coords = get2DCoordinatesOfIndex(i, cols);
    const noise = shadeNoise.getValue(coords);
    signArray[i].setShade(noise);
    signArray[i].update();
  }
  shadeNoise.move();

  if (mode == "auto") {
    if (Math.random() > 0.994 && links.length > 0) {
      // debugger;
      // const rndIndex = Math.floor(Math.random() * links.length);
      // const textObj = links[rndIndex];
      const textObj = liveData.textObject[0]
      const color = colorArray[Math.floor(Math.random() * colorArray.length)];
      showText(textObj, true, color);
    }

    if (Math.random() > 0.97) {
      // debugger;
      const rndIndex = Math.floor(Math.random() * phrases.length);
      const textObj = phrases[rndIndex];
      const color = colorArray[Math.floor(Math.random() * colorArray.length)];
      // debugger;
      showText(textObj, true, "#ffffff");
    }
  }
}

function showText(textObj, rnd, color) {
  //   debugger;
  // const {x,y,direcRow, text, link} = textObj;
  let updTextObj = { ...textObj };
  //   text = textObj;

  if (rnd) {
    if (Math.random() > 0.5) {
      //   debugger;
      updTextObj.direcRow = !textObj.direcRow;
    }
    const coords = findRndPos(updTextObj.text, updTextObj.direcRow);
    updTextObj.x = coords.x;
    updTextObj.y = coords.y;
    // debugger;
  }

  fadeInText(updTextObj, color);
}

async function fadeInText(textObj, color) {
  const { x, y, direcRow, text, link } = textObj;
  let itemArray = [];

  for (let i = 0; i < text.length; i++) {
    let item, index;
    // if (i == 1) debugger;
    if (direcRow) {
      index = getIndexOf2DCoordinates({ x: x + i, y: y }, cols);
    } else {
      index = getIndexOf2DCoordinates({ x: x, y: y + i }, cols);
    }

    item = signArray[index];
    item.isLockedColor = true;
    itemArray.push(item);

    await animation({
      duration: 200,
      timing(timeFraction) {
        return timeFraction;
      },
      draw(progress, elapsedSinceLastExecute, elapsed) {
        // console.log("start");
        // let i = Math.floor(progress * text.length);
        // signArray[index].displayInfo(text[i], color);
        // item.isLockedColor = true;
        item.setRndSignFromCharset();
      },
      end() {
        item.displayInfo(text[i], color, link);
      },
      name: "Font Fade",
      fps: 20,
    });
  }

  //   await console.log("test");
  await setDelay(4000);

  for (const item of itemArray) {
    await animation({
      duration: 100,
      timing(timeFraction) {
        return timeFraction;
      },
      draw(progress, elapsedSinceLastExecute, elapsed) {
        // console.log("afterwards");
        // let i = Math.floor(progress * text.length);
        // item.displayInfo(text[i], color);
        item.isLockedSign = false;
        item.isLockedColor = false;
        item.setRndSignFromCharset();
        item.setColor("#ffffff");
      },
      end() {
        // signArray[index].displayInfo(text[i], color);
        item.isLockedColor = false;
        item.removeLinks();
      },
      name: "Font Fade",
      fps: 20,
    });

    // signArray[index].displayInfo(text[i], color);
  }
}

function setDelay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, ms);
  });
}

function findRndPos(text, direcRow) {
  const textLength = text.length;

  if (direcRow) {
    const xMax = cols - textLength;
    return {
      x: Math.floor(Math.random() * xMax),
      y: Math.floor(Math.random() * rows),
    };
  } else {
    const yMax = rows - textLength;
    return {
      y: Math.floor(Math.random() * yMax),
      x: Math.floor(Math.random() * cols),
    };
  }
}

function afterResize() {
  // console.log("rsizing done");
  location.reload();
  //   reset();
}

// Listen for resize changes
window.addEventListener(
  "resize",
  function () {
    // Get screen size (inner/outerWidth, inner/outerHeight)
    // reset()

    clearTimeout(windowResizeTimeOutId);

    // setTimeout returns the numeric ID which is used by
    // clearTimeOut to reset the timer
    windowResizeTimeOutId = setTimeout(afterResize, 500);
    // console.log("rsizing");
  },
  false
);
