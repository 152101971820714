export const links = [
  {
    x: 10,
    y: 10,
    direcRow: true,
    text: "52°18'37.0\"N 12°22'17.4\"E",
    link: "https://goo.gl/maps/vHdYbGuwhVF7FZfF7",
  },
];


export const phrases = [
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "TEST AREA",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "LIKE VIEW",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "TAKE BACK",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "MEME MORI",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "LOVE DROP",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "CODE WORD",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "UNDO REDO",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "TEST FILE",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "RICH POOR",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "SING SIGN",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "SAFE RISK",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "PSYC LAND",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "KIND EDIT",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "YOUR MOON",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "HYPE TEXT",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "FULL TIME",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "COPY COPY",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "COMP TRUE",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "SOFT EDIT",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "DOOM SOON",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "PLUS NULL",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "GLAS SKIN",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "META LOVE",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "WORN EYES",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "TASK HOWL",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "MUSE DARE",
    link: "",
  },
  {
    x: undefined,
    y: undefined,
    direcRow: true,
    text: "COLD MASS",
    link: "",
  },
];

export const menu = [
  {
    text: "MUSIC",
    link: "https://www.monkeytownrecords.com/releases/?page=1&a=moderat"
  },
  {
    text: "MERCH",
    link: "https://www.monkeytownrecords.com/shop/?page=1&a=moderat"
  },
  {
    text: "TICKETS",
    link: "https://www.monkeytowntickets.com/tickets"
  },
  {
    text: "INFO",
    link: "https://www.monkeytownrecords.com/artist/moderat/ "
  }
]