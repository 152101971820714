export const mapRange = (value, x1, y1, x2, y2) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;

export function getIndexOf2DCoordinates(coords, columns) {
  return coords.x + columns * coords.y;
}

export function get2DCoordinatesOfIndex(index, columns) {
  return {
    x: Math.floor(index % columns),
    y: Math.floor(index / columns),
  };
}
export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export async function animate({ timing, draw, end, duration, name, fps }) {
  return new Promise((resolve, reject) => {
    let start = performance.now();
    const interval = 1000 / fps;
    let elapsedSinceLastExecute = start;
    let resetStart = start;

    // console.log('interval ' + interval);
    // debugger;

    const requestID = requestAnimationFrame(function animate(time) {
      // time = execution of callback time
      // timeFraction goes from 0 to 1
      // debugger;
      let elapsed = time - start;
      elapsedSinceLastExecute = time - resetStart;

      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;

      // calculate the current animation state
      let progress = timing(timeFraction);
      if (elapsedSinceLastExecute > interval) {
        // console.log('start, time, elasped: ');
        // console.log(start);
        // console.log(time);
        // console.log(elapsed);

        draw(progress, elapsedSinceLastExecute, elapsed); // draw it

        // if (name != "Main Loop") {
        // console.log("name: " + name);
        // console.log('time: ' + time, elapsed / 1000);
        // console.log("progress: " + progress);
        // }

        resetStart = time - (elapsed % interval);
      }
      if (timeFraction < 1 || duration == 0) {
        requestAnimationFrame(animate);
      }
      if (timeFraction >= 1 && duration != 0) {
        //   debugger;
        // console.log(`Animation "${name}" has ended`);
        if (end != undefined) {
          end();
        }
        cancelAnimationFrame(requestID);
        resolve("done!");
      }
      if (duration == 0) {
        resolve("done!");
      }
    });
  });
}
