import * as PIXI from "pixi.js";
import { Sign } from "./Sign";

export function initPixi(reso, fontSize) {
  let type = "WebGL";
  if (!PIXI.utils.isWebGLSupported()) {
    type = "canvas";
  }

  PIXI.utils.sayHello(type);
  PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
  PIXI.settings.ROUND_PIXELS = true;
  PIXI.settings.PRECISION_FRAGMENT = "highp";
  // this.renderer = PIXI.autoDetectRenderer(845, 451, {
  //   antialias: false,
  //   transparent: true,
  // });

  //Create a Pixi Application
  // const app = new PIXI.Application({ width: 256, height: 256 });
  const app = new PIXI.Application({
    width: 128, // default: 800
    height: 128, // default: 600
    antialias: true, // default: false
    // transparent: false, // default: false
    resolution: 2, // default: 1
  });

  //   app.renderer.resize(512, 512);

  //Add the canvas that Pixi automatically created for you to the HTML document
  document.querySelector('.animations').appendChild(app.view);
  // app.renderer.view.style.position = "absolute";
  app.renderer.view.style.display = "block";
  app.renderer.autoResize = true;
  app.renderer.autoDensity = true;
  // app.render.antialias = false;
  app.renderer.backgroundColor = 0x000000;
  app.renderer.autoDensity = true;
  app.render.antialias = false;
  app.render.transparent = true;

  app.resizeTo = document.querySelector('.animations');

  const signMap = new Map();

  const cols = Math.floor(app.screen.width / reso);
  const rows = Math.floor(app.screen.height / reso);
  const colWidth = app.screen.width / cols;
  const rowHeight = app.screen.height / rows;

  const fontName = "cassius mod";
  PIXI.BitmapFont.from(
    fontName,
    {
      fontFamily: "Cassius-Regular",
      fill: "#FFFFFF",
      fontSize: fontSize,
      align: "center",
      // // anchor: (0.5, 0.5),
      // anchor: new PIXI.Point(1),
    },
    {
      chars: [["a", "z"], ["0", "9"], ["A", "Z"], "§?!"],
    },
    { resolution: 2 }
    // { resolution: devicePixelRatio }
  );

  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      const initSign = "§";
      // const bitmapTexty = new PIXI.BitmapText(initSign, { fontName });
      const bitmapTexty = new PIXI.Text(initSign);
      bitmapTexty.style = new PIXI.TextStyle({
        fontFamily: "Cassius-Regular",
        fill: "0xFFFFFF",
        fontSize: fontSize,
        align: "center",
      });
      //   signArray.push(bitmapTexty);
      bitmapTexty.position.set(
        x * colWidth + colWidth / 2 - bitmapTexty.width / 2,
        y * rowHeight + rowHeight / 2 - bitmapTexty.height / 2
      );
      bitmapTexty.alpha = 0;
      bitmapTexty.updateText();
      bitmapTexty.interactive = true;
      bitmapTexty.hitArea = new PIXI.Rectangle(0, 0, colWidth, rowHeight);

      // const border = new PIXI.Graphics();
      const target = new PIXI.Graphics();
      target.lineStyle(1, 0xffffff);
      target.beginFill(0xff0000, 0.15);
      target.drawRect(x * colWidth, y * rowHeight, colWidth, rowHeight);
      target.endFill();

      // const test = new PIXI.Graphics();
      // test.lineStyle(1, 0xffffff);
      // test.beginFill(0x00ff00, 0.25);
      // test.drawRect(x * colWidth, y * rowHeight, 20, 20);
      // test.endFill();

      // border.lineStyle(1, 0xffffff);
      // border.beginFill(0xffffff, 0.25);
      // border.drawRect(
      //   bitmapTexty.x,
      //   bitmapTexty.y,
      //   bitmapTexty.width,
      //   bitmapTexty.height
      // );
      // border.endFill();

      // bitmapTexty.on("mousedown", onDown);
      // bitmapTexty.on("touchstart", onDown);

      // function onDown(eventData) {
      //   console.log(eventData.currentTarget);
      //   console.log(eventData.target.text);
      //   // mode = "clean";
      //   // debugger;
      //   clickFunc();
      //   // const item = signMap.get(eventData.currentTarget);
      //   item.isRndChar = true;
      // }
      app.stage.addChild(bitmapTexty);
      // app.stage.addChild(target);
      signMap.set(bitmapTexty, new Sign(bitmapTexty));
    }
  }

  // console.log(signArray);
  // console.log(signMap);
  // console.log(app.screen.width + " x " + app.screen.height);
  // console.log(
  // `${cols} cols a ${colWidth}px and ${rows} rows a ${rowHeight}px making ${signMap.size} GridItems`
  // );

  return { PIXI: PIXI, app: app, signMap: signMap, cols: cols, rows: rows };
}

function getCenterPos(el) { }
