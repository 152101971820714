import { mapRange } from "./tools";
import chroma from "chroma-js";
import { utils } from "pixi.js";

// console.log(utils);
export class Sign {
  constructor(bitmapText) {
    // this.color = "#FFFFFF";
    this.bitmapText = bitmapText;
    this.charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890§";
    this.isRndChar = false;
    this.link = "";
    this.isLockedColor = false;
    this.isLockedSign = false;
    this.initialSign = "§";
    this.isMenu = false;
    this.iterCounter = 0;
    this.bitmapText.on("mouseover", () => {
      this.setColor("#ffffff");
      //   this.setColor("#ff0000");
      this.setRndSignFromCharset();
    });
    //   "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890?!$§%&/()=“¡¢[]|{}≠¿ÜÄÖ*#><";
  }

  setRndSignFromCharset() {
    // debugger;
    if (!this.isMenu) {
      const rndSign =
        this.charset[Math.floor(Math.random() * this.charset.length)];
      this.setSign(rndSign);
    }
  }

  setSign(sign) {
    if (!this.isLockedSign && !this.isMenu) {
      this.bitmapText.text = sign;
    }
  }

  setShade(noise) {
    if (!this.isLockedColor && !this.isMenu) {
      const alpha = mapRange(noise, -1, 1, 0.2, 0.8);
      const c = chroma("white").set("hsl.l", alpha).hex();
      this.setColor(c);
    }
  }

  update() {
    if (this.isRndChar) {
      this.setRndSignFromCharset();
    }
  }

  setColor(hex) {
    if (!this.isLockedColor && !this.isMenu) {
      this.bitmapText.tint = utils.string2hex(hex);
    }
  }

  getColor() {
    return this.bitmapText.tint;
  }

  iterCharSet() {
    this.bitmapText.text = this.charset[this.iterCounter % this.charset.length];
    this.iterCounter++;
  }

  displayInfo(sign, color, link) {
    // debugger;
    if (link != "") this.setUpLink(link);

    if (!this.isMenu) {
      this.isLockedColor = false;
      this.isLockedSign = false;

      this.setSign(sign);
      this.setColor(color);

      this.isLockedColor = true;
      this.isLockedSign = true;
    }
  }

  setUpLink(link) {
    if (!this.isMenu) {
      this.link = link;
      this.bitmapText.interactive = true;
      this.bitmapText.on("mousedown", () => {
        window.open(this.link);
      });

      this.bitmapText.on("touchstart", () => {
        this.openWindow(link);
      });

      this.bitmapText.cursor = "pointer";
    }
  }

  removeLinks() {
    if (!this.isMenu) {
      this.link = "";
      this.bitmapText.interactive = false;

      this.bitmapText.cursor = "default";
    }
  }

  openWindow(link) {
    // console.log("opening");
    // var result = window.open(link, "_self");
    window.location.href = link;
    // console.log("result", result);
  }
}
