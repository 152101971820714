import { menu } from './textObj';

let colorClasses = [
  'red', 'green', 'blue'
]

export class Menubar {
  constructor() {
    this.element = document.querySelector('.menubar-items');

    let items = menu.map(item => {
      return this.createLink(item.text, item.link);
    })

    let colorCounter = 0;

    for (let i = 0; i < 10; i++) {
      // items.forEach(item => {
      //   this.element.appendChild(item);
      // })
      menu.forEach(item => {
        let a = this.createLink(item.text, item.link);
        a.classList.add(colorClasses[colorCounter % colorClasses.length])
        colorCounter++;
        this.element.appendChild(a);
      })
    }
  }

  createLink(text, link) {
    let a = document.createElement('a');
    a.setAttribute('href', link);
    a.setAttribute('target', '_blank');
    a.appendChild(document.createTextNode(text));

    return a;
  }

  show() {
    document.querySelector('.menubar').classList.add('menubar--visible');
  }
}