// import { menu } from './textObj';
import coords from 'coords-formater'

window.coords = coords;

let uri = 'liveapi.moderat.fm/ws'
let defaultText = [
  {
    x: 10,
    y: 10,
    direcRow: true,
    text: "52°18'37.0\"N 12°22'17.4\"E",
    link: "https://goo.gl/maps/vHdYbGuwhVF7FZfF7",
  },
];
let mockData = `{"firstname":"Oliver","lat":50.938426,"lon":-4.524108,"heading":315,"dt":1653457948,"href":"https://www.google.com/maps/place/50.938426,-4.524108"}
{"firstname":"Oliver","lat":50.938427,"lon":-4.524069,"heading":315,"dt":1653457979,"href":"https://www.google.com/maps/place/50.938427,-4.524069"}
{"firstname":"Oliver","lat":50.938427,"lon":-4.524069,"heading":0,"dt":1653457992,"href":"https://www.google.com/maps/place/50.938427,-4.524069"}
{"firstname":"Dennis","lat":52.469927,"lon":13.426778,"heading":150,"dt":1653465728,"href":"https://www.google.com/maps/place/52.469927,13.426778"}
{"firstname":"Dennis","lat":52.469951,"lon":13.426788,"heading":268,"dt":1653465748,"href":"https://www.google.com/maps/place/52.469951,13.426788"}
{"firstname":"Dennis","lat":52.469951,"lon":13.426788,"heading":269,"dt":1653465779,"href":"https://www.google.com/maps/place/52.469951,13.426788"}
{"firstname":"Dennis","lat":52.469951,"lon":13.426788,"heading":53,"dt":1653465809,"href":"https://www.google.com/maps/place/52.469951,13.426788"}
{"firstname":"Dennis","lat":52.469927,"lon":13.426778,"heading":278,"dt":1653465840,"href":"https://www.google.com/maps/place/52.469927,13.426778"}
{"firstname":"Dennis","lat":52.469951,"lon":13.426788,"heading":61,"dt":1653465901,"href":"https://www.google.com/maps/place/52.469951,13.426788"}
{"firstname":"Dennis","lat":52.469927,"lon":13.426778,"heading":354,"dt":1653465932,"href":"https://www.google.com/maps/place/52.469927,13.426778"}`;

export class LiveData {
  constructor() {
    this.textObject = defaultText;

    this.url = 'ws://' + uri;
    if (document.location.protocol === 'https:') {
      this.url = 'wss://' + uri;
    }

    // console.log('URL:', this.url);

    this.connect();
    // console.log('sending mock data');
    // this.onMessage({ data: mockData });
  }

  connect() {
    if (this.connection) this.connection.close();
    this.connection = new WebSocket(this.url);

    this.connection.onmessage = this.onMessage.bind(this);
    this.connection.onclose = this.onClose.bind(this);
    this.connection.onmessage = this.onMessage.bind(this);
  }

  onOpen(event) {
    // console.log('ws connected', event);
  }

  onMessage(event) {
    // console.log('on message', event);
    let lines = event.data.split('\n');
    lines.forEach(line => {
      this.parseData(JSON.parse(line));
    })
  }

  parseData(data) {
    // console.log('got:', data);
    let latp = coords.parse(data.lat);
    let lonp = coords.parse(data.lon);

    latp.seconds = latp.seconds.toFixed(1)
    lonp.seconds = lonp.seconds.toFixed(1)

    let lat = coords.toString(latp, { spaces: false });
    let lon = coords.toString(lonp, { spaces: false });

    this.textObject[0].link = data.href;
    this.textObject[0].text = `${data.firstname.toUpperCase()} @ ${lat}N ${lon}E`
    // console.log('parsed line to:', this.textObject[0]);
    // console.log('latp', data.lat)
  }

  onClose(event) {
    console.log('ws closed', event);
    console.log('trying to reconnect');
    setTimeout(this.connect, 1000);
  }
}


// window.onload = function () {
//   var conn;
//   var log = document.getElementById("log");

//   function connect(reconnect) {
//     let protocol = "ws://";
//     if (document.location.protocol === 'https:') {
//       protocol = "wss://";
//     }
//     const websocketUrl = protocol + document.location.host + "/ws"
//     console.log(websocketUrl);
//     conn = new WebSocket(websocketUrl);
//     conn.onclose = function (evt) {
//       var item = document.createElement("div");
//       item.innerHTML = "<b>Connection closed. Attempting reconnect...</b>";
//       appendLog(item);
//       setTimeout(connect, 1000, true);
//     };
//     conn.onmessage = function (evt) {
//       var messages = evt.data.split('\n');
//       for (var i = 0; i < messages.length; i++) {
//         var item = document.createElement("div");
//         const payload = JSON.parse(messages[i]);
//         console.log(payload);
//         item.innerHTML = payload.firstname + '@' + payload.dt + ' <a href="' + payload.href + '">' + payload.lat + ',' + payload.lon + '</a>';
//         appendLog(item);
//       }
//     };
//     if (reconnect) {
//       var item = document.createElement("div");
//       item.innerHTML = "<b>Reconnected!</b>";
//       appendLog(item);
//     }
//   }

//   if (window["WebSocket"]) {
//     connect();
//   } else {
//     var item = document.createElement("div");
//     item.innerHTML = "<b>Your browser does not support WebSockets.</b>";
//     appendLog(item);
//   }
// };

// var b = new WebSocket('wss://d4ta.tsak.net/ws'); b.onmessage = function (e) { console.log('e:', e.data.replace(/(\r\n|\n|\r)/gm, "").split('}{')); console.log(typeof e.data) }

// }
// { "firstname": "Oliver", "lat": 50.93833, "lon": -4.524095, "heading": 315, "dt": 1653457747, "href": "https://www.google.com/maps/place/50.938330,-4.524095" }
// { "firstname": "Oliver", "lat": 50.938427, "lon": -4.524107, "heading": 315, "dt": 1653457809, "href": "https://www.google.com/maps/place/50.938427,-4.524107" }
